import React from 'react';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';

const TabPPF = () => {
    return (
        <div>
            <div className="row">
                    <div className="col-lg-12">
                        <Tabs>
                            <div className="row row--30">
                                <div className="col-lg-6">
                                    <img className="radius-small" src='./images/product/product-ppf.jpg' alt="Corporate React Template" />
                                </div>
                                <div className="col-lg-6 mt_md--40 mt_sm--40">
                                    <div className="rn-default-tab">
                                        <div className="tab-button-panel">
                                            <TabList className="tab-button">
                                                <Tab>
                                                    <div className="rn-tab-button">
                                                        <button>PVC</button>
                                                    </div>
                                                </Tab>
                                                <Tab>
                                                    <div className="rn-tab-button">
                                                        <button>TPH</button>
                                                    </div>
                                                </Tab>
                                                <Tab>
                                                    <div className="rn-tab-button">
                                                        <button>TPU</button>
                                                    </div>
                                                </Tab>
                                            </TabList>
                                        </div>

                                        <div className="tab-content-panel">
                                        <TabPanel>
                                            <div className="rn-tab-content">
                                                <div className="inner">
                                                    <h5>The Crystal clear polymeric film origin from Germany</h5>
                                                    <ul>
                                                        <li>High quality and consistency for control during manufacturing processes</li>
                                                        <li>Crystal clear – invisibility on any surface</li>
                                                        <li>Protecting surfaces from damage by impact and abrasion</li>
                                                        <li>Compatible with adhesives, inks, and coatings</li>
                                                        <li>Self-healing properties maintain aesthetic quality</li>
                                                        <li>Resistant to the effects of environmental exposure – col when exposed to UV</li>
                                                        <li>Won’t em-brittle over time</li>
                                                    </ul>
                                                    <h5>Removable solvent acrylic pressure sensitive adhesives</h5>
                                                    <ul>
                                                        <li>Optically clear adhesives</li>
                                                        <li>Stable adhesion</li>
                                                        <li>Excellent aging characteristics</li>
                                                        <li>UV resistant</li>
                                                        <li>Clean removal</li>
                                                        <li>Non-Yellowing</li>
                                                        <li>Chemical / moisture / heat resistance</li>
                                                    </ul>
                                                    <h5>Service Life</h5>
                                                    <p>Based on regular Europe continental climate and proper care,  maximum 2 years’ limited warranty.</p>
                                                </div>
                                            </div>
                                        </TabPanel>

                                        <TabPanel>
                                            <div className="rn-tab-content">
                                                <div className="inner">
                                                    <h5>THE STABLE QUALITY TPH FILM AND NANOSCALE COATING PROCESSES.</h5>
                                                    <ul>
                                                        <li>High quality and consistency for control during manufacturing processes</li>
                                                        <li>Crystal clear – invisibility on any surface</li>
                                                        <li>Protecting surfaces from damage by impact and abrasion</li>
                                                        <li>Compatible with adhesives, inks, and coatings</li>
                                                        <li>Self-healing properties maintain aesthetic quality</li>
                                                        <li>Resistant to the effects of environmental exposure – col when exposed to UV</li>
                                                        <li>Won’t em-brittle over time</li>
                                                    </ul>
                                                    <h5>ASHLAND SOLVENT ACRYLIC PRESSURE SENSITIVE ADHESIVES</h5>
                                                    <ul>
                                                        <li>Optically clear adhesives</li>
                                                        <li>Stable adhesion</li>
                                                        <li>Excellent aging characteristics</li>
                                                        <li>UV resistant</li>
                                                        <li>Clean removal</li>
                                                        <li>Non-Yellowing</li>
                                                        <li>Chemical / moisture / heat resistance</li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </TabPanel>

                                        <TabPanel>
                                            <div className="rn-tab-content">
                                                <div className="inner">
                                                    <h5>THE FILM OF LUBRIZOL SPECIAL THERMOPLASTIC POLYURETHANE MOLECULAR</h5>
                                                    <h5>RECOMBINATION TECHNOLOGY AND NANOSCALE COATING PROCESSES.</h5>
                                                    <ul>
                                                        <li>Lubrizol Surface Protection Solutions are proven by more than 30 years of experience in the harshest environments. Road salt, flying stones, acid rain, and extreme hot and cold to name a few!</li>
                                                        <li>High quality and consistency for control during manufacturing processes</li>
                                                        <li>Crystal clear – invisibility on any surface</li>
                                                        <li>Protecting surfaces from damage by impact and abrasion</li>
                                                        <li>Compatible with adhesives, inks, and coatings</li>
                                                        <li>Self-healing properties maintain aesthetic quality</li>
                                                        <li>Resistant to the effects of environmental exposure – col when exposed to UV</li>
                                                        <li>Plasticizer-free – won’t em-brittle over time</li>
                                                    </ul>
                                                    <h5>ASHLAND SOLVENT ACRYLIC PRESSURE SENSITIVE ADHESIVES</h5>
                                                    <ul>
                                                        <li>Optically clear adhesives</li>
                                                        <li>Stable adhesion</li>
                                                        <li>Excellent aging characteristics</li>
                                                        <li>UV resistant</li>
                                                        <li>Clean removal</li>
                                                        <li>Non-Yellowing</li>
                                                        <li>Chemical / moisture / heat resistance</li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </TabPanel>

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </Tabs>
                    </div>
                </div>
        </div>
    )
}

export default TabPPF
