import React from 'react';
import {BrowserRouter as Router, Switch, Route} from 'react-router-dom';
import PageScrollTop from './components/pageToTop/PageScrollTop'

// Pages import Here
import AboutUs from "./pages/AboutUs";
import Contact from "./pages/Contact";
import Error from "./pages/Error";
import Gallery from "./pages/Gallery";
import Home from './pages/Home';
import Product from './pages/Product';


// Import Css Here
import './assets/scss/style.scss';

// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyDdgLNRZ5IHjXxaHmKAphnlPJj-feB32yw",
  authDomain: "tj-autoworks.firebaseapp.com",
  projectId: "tj-autoworks",
  storageBucket: "tj-autoworks.appspot.com",
  messagingSenderId: "1006261414513",
  appId: "1:1006261414513:web:8c295747f4df0659ff09d0",
  measurementId: "G-T9K7KPYDBC"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);

const App = () => {
    return (
        <Router>
            <PageScrollTop>
                <Switch>
                    <Route path={`${process.env.PUBLIC_URL + "/"}`} exact component={Home}/>
                    <Route path={`${process.env.PUBLIC_URL + "/product"}`} exact component={Product}/>
                    <Route path={`${process.env.PUBLIC_URL + "/gallery"}`} exact component={Gallery}/>
                    <Route path={`${process.env.PUBLIC_URL + "/about-us"}`} exact component={AboutUs}/>
                    <Route path={`${process.env.PUBLIC_URL + "/contact"}`} exact component={Contact}/>
                    <Route component={Error}/>

                </Switch>
            </PageScrollTop>
        </Router>
    )
}


export default App
