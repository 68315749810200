import React from 'react';
import SEO from "../common/SEO";
import Layout from "../common/Layout";
import BreadcrumbOne from "../elements/breadcrumb/BreadcrumbOne";
import SectionTitle from "../elements/sectionTitle/SectionTitle";
import GalleryOne from "../elements/gallery/GalleryOne";

const PaintProtectionData = [
    {
        id: "01",
        image: "./images/gallery/paint-protection/01/lamborghini-01.jpg",
        popupLink: [
            "./images/gallery/paint-protection/01/lamborghini-01.jpg",
            "./images/gallery/paint-protection/01/lamborghini-02.jpg",
            "./images/gallery/paint-protection/01/lamborghini-03.jpg",
            "./images/gallery/paint-protection/01/lamborghini-04.jpg",
            "./images/gallery/paint-protection/01/lamborghini-05.jpg",
            "./images/gallery/paint-protection/01/lamborghini-06.jpg",
            "./images/gallery/paint-protection/01/lamborghini-07.jpg",
            "./images/gallery/paint-protection/01/lamborghini-08.jpg",
        ],
    },
    {
        id: "02",
        image: "./images/gallery/paint-protection/02/maybach-01.jpg",
        popupLink: [
            "./images/gallery/paint-protection/02/maybach-01.jpg",
            "./images/gallery/paint-protection/02/maybach-02.jpg",
            "./images/gallery/paint-protection/02/maybach-03.jpg",
            "./images/gallery/paint-protection/02/maybach-04.jpg",
            "./images/gallery/paint-protection/02/maybach-05.jpg",
            "./images/gallery/paint-protection/02/maybach-06.jpg",
            "./images/gallery/paint-protection/02/maybach-07.jpg",
            "./images/gallery/paint-protection/02/maybach-08.jpg",
            "./images/gallery/paint-protection/02/maybach-09.jpg",
            "./images/gallery/paint-protection/02/maybach-10.jpg",
            "./images/gallery/paint-protection/02/maybach-11.jpg",
            "./images/gallery/paint-protection/02/maybach-12.jpg",
            "./images/gallery/paint-protection/02/maybach-13.jpg",
            "./images/gallery/paint-protection/02/maybach-14.jpg",
            "./images/gallery/paint-protection/02/maybach-15.jpg",
            "./images/gallery/paint-protection/02/maybach-16.jpg",
        ],
    },
    {
        id: "03",
        image: "./images/gallery/paint-protection/03/ford-01.jpg",
        popupLink: [
            "./images/gallery/paint-protection/03/ford-01.jpg",
            "./images/gallery/paint-protection/03/ford-02.jpg",
            "./images/gallery/paint-protection/03/ford-03.jpg",
            "./images/gallery/paint-protection/03/ford-04.jpg",
            "./images/gallery/paint-protection/03/ford-05.jpg",
            "./images/gallery/paint-protection/03/ford-06.jpg",
        ],
    },
    {
        id: "04",
        image: "./images/gallery/paint-protection/04/v250-01.jpg",
        popupLink: [
            "./images/gallery/paint-protection/04/v250-01.jpg",
            "./images/gallery/paint-protection/04/v250-02.jpg",
            "./images/gallery/paint-protection/04/v250-03.jpg",
            "./images/gallery/paint-protection/04/v250-04.jpg",
            "./images/gallery/paint-protection/04/v250-05.jpg",
            "./images/gallery/paint-protection/04/v250-06.jpg",
            "./images/gallery/paint-protection/04/v250-07.jpg",
            "./images/gallery/paint-protection/04/v250-08.jpg",
            "./images/gallery/paint-protection/04/v250-09.jpg",
            "./images/gallery/paint-protection/04/v250-10.jpg",
        ],
    },
    {
        id: "05",
        image: "./images/gallery/paint-protection/05/rollsroyce-01.jpg",
        popupLink: [
            "./images/gallery/paint-protection/05/rollsroyce-01.jpg",
            "./images/gallery/paint-protection/05/rollsroyce-02.jpg",
            "./images/gallery/paint-protection/05/rollsroyce-03.jpg",
            "./images/gallery/paint-protection/05/rollsroyce-04.jpg",
            "./images/gallery/paint-protection/05/rollsroyce-05.jpg",
        ],
    },
    {
        id: "06",
        image: "./images/gallery/paint-protection/06/ford-01.jpg",
        popupLink: [
            "./images/gallery/paint-protection/06/ford-01.jpg",
            "./images/gallery/paint-protection/06/ford-02.jpg",
            "./images/gallery/paint-protection/06/ford-03.jpg",
            "./images/gallery/paint-protection/06/ford-04.jpg",
            "./images/gallery/paint-protection/06/ford-05.jpg",
            "./images/gallery/paint-protection/06/ford-06.jpg",
            "./images/gallery/paint-protection/06/ford-07.jpg",
        ],
    },
];


const UVProtectionData = [
    {
        id: "01",
        image: "./images/gallery/uv/image-01.jpg?20240307",
        popupLink: [
            "./images/gallery/uv/image-01.jpg",
        ],
    },
    {
        id: "02",
        image: "./images/gallery/uv/image-02.jpg",
        popupLink: [
            "./images/gallery/uv/image-02.jpg",
        ],
    },
    {
        id: "03",
        image: "./images/gallery/uv/image-03.jpg",
        popupLink: [
            "./images/gallery/uv/image-03.jpg",
        ],
    },
    {
        id: "04",
        image: "./images/gallery/uv/image-04.jpg",
        popupLink: [
            "./images/gallery/uv/image-04.jpg",
        ],
    },
    {
        id: "05",
        image: "./images/gallery/uv/image-05.jpg",
        popupLink: [
            "./images/gallery/uv/image-05.jpg",
        ],
    },
    {
        id: "06",
        image: "./images/gallery/uv/image-06.jpg",
        popupLink: [
            "./images/gallery/uv/image-06.jpg",
        ],
    },
];

const ColorChangeWrapsData = [
    {
        id: "01",
        image: "./images/gallery/color-change/01/image-01.jpg",
        popupLink: [
            "./images/gallery/color-change/01/image-01.jpg",
            "./images/gallery/color-change/01/image-02.jpg",
            "./images/gallery/color-change/01/image-03.jpg",
            "./images/gallery/color-change/01/image-04.jpg",
            "./images/gallery/color-change/01/image-05.jpg",
            "./images/gallery/color-change/01/image-06.jpg",
            "./images/gallery/color-change/01/image-07.jpg",
            "./images/gallery/color-change/01/image-08.jpg",
            "./images/gallery/color-change/01/image-09.jpg",
            "./images/gallery/color-change/01/image-10.jpg",
            "./images/gallery/color-change/01/image-11.jpg",
            "./images/gallery/color-change/01/image-12.jpg",
            "./images/gallery/color-change/01/image-13.jpg",
            "./images/gallery/color-change/01/image-14.jpg",
            "./images/gallery/color-change/01/image-15.jpg",
            "./images/gallery/color-change/01/image-16.jpg",
            "./images/gallery/color-change/01/image-17.jpg",
            "./images/gallery/color-change/01/image-18.jpg",
            "./images/gallery/color-change/01/image-19.jpg",
            "./images/gallery/color-change/01/image-20.jpg",
            "./images/gallery/color-change/01/image-21.jpg",
            "./images/gallery/color-change/01/image-22.jpg",
            "./images/gallery/color-change/01/image-23.jpg",
            "./images/gallery/color-change/01/image-24.jpg",
            "./images/gallery/color-change/01/image-25.jpg",
            "./images/gallery/color-change/01/image-26.jpg",
            "./images/gallery/color-change/01/image-27.jpg",
            "./images/gallery/color-change/01/image-28.jpg",
        ],
    },
    {
        id: "02",
        image: "./images/gallery/color-change/02/image-01.jpg",
        popupLink: [
            "./images/gallery/color-change/02/image-01.jpg",
            "./images/gallery/color-change/02/image-02.jpg",
            "./images/gallery/color-change/02/image-03.jpg",
            "./images/gallery/color-change/02/image-04.jpg",
            "./images/gallery/color-change/02/image-05.jpg",
            "./images/gallery/color-change/02/image-06.jpg",
            "./images/gallery/color-change/02/image-07.jpg",
        ],
    },
    {
        id: "03",
        image: "./images/gallery/color-change/03/image-01.jpg",
        popupLink: [
            "./images/gallery/color-change/03/image-01.jpg",
            "./images/gallery/color-change/03/image-02.jpg",
            "./images/gallery/color-change/03/image-03.jpg",
            "./images/gallery/color-change/03/image-04.jpg",
            "./images/gallery/color-change/03/image-05.jpg",
            "./images/gallery/color-change/03/image-06.jpg",
            "./images/gallery/color-change/03/image-07.jpg",
            "./images/gallery/color-change/03/image-08.jpg",
            "./images/gallery/color-change/03/image-09.jpg",
            "./images/gallery/color-change/03/image-10.jpg",
            "./images/gallery/color-change/03/image-11.jpg",
            "./images/gallery/color-change/03/image-12.jpg",
            "./images/gallery/color-change/03/image-13.jpg",
        ],
    },
    {
        id: "04",
        image: "./images/gallery/color-change/04/image-01.jpg",
        popupLink: [
            "./images/gallery/color-change/04/image-01.jpg",
            "./images/gallery/color-change/04/image-02.jpg",
            "./images/gallery/color-change/04/image-03.jpg",
        ],
    },
    {
        id: "05",
        image: "./images/gallery/color-change/05/image-01.jpg",
        popupLink: [
            "./images/gallery/color-change/05/image-01.jpg",
            "./images/gallery/color-change/05/image-02.jpg",
            "./images/gallery/color-change/05/image-03.jpg",
            "./images/gallery/color-change/05/image-04.jpg",
        ],
    },
    {
        id: "06",
        image: "./images/gallery/color-change/06/image-01.jpg",
        popupLink: [
            "./images/gallery/color-change/06/image-01.jpg",
            "./images/gallery/color-change/06/image-02.jpg",
            "./images/gallery/color-change/06/image-03.jpg",
            "./images/gallery/color-change/06/image-04.jpg",
            "./images/gallery/color-change/06/image-05.jpg",
            "./images/gallery/color-change/06/image-06.jpg",
        ],
    },
];

const WindshieldProtectionData = [
    {
        id: "01",
        image: "./images/gallery/windshield/image-01.jpg",
        popupLink: [
            "./images/gallery/windshield/image-01.jpg",
        ],
    },
    {
        id: "02",
        image: "./images/gallery/windshield/image-02.jpg",
        popupLink: [
            "./images/gallery/windshield/image-02.jpg",
        ],
    },
    {
        id: "03",
        image: "./images/gallery/windshield/image-03.jpg",
        popupLink: [
            "./images/gallery/windshield/image-03.jpg",
        ],
    },
    {
        id: "04",
        image: "./images/gallery/windshield/image-04.jpg",
        popupLink: [
            "./images/gallery/windshield/image-04.jpg",
        ],
    },
    {
        id: "05",
        image: "./images/gallery/windshield/image-05.jpg",
        popupLink: [
            "./images/gallery/windshield/image-05.jpg",
        ],
    },
    {
        id: "06",
        image: "./images/gallery/windshield/image-06.jpg",
        popupLink: [
            "./images/gallery/windshield/image-06.jpg",
        ],
    },
];

const Gallery = () => {
    return (
        <>
            <SEO title="Gallery" description="View stunning examples of our work in our gallery. From car wrapping and paint protection to UV shielding and more, see how we've transformed vehicles for our satisfied customers. Get inspired today!" />
            <Layout>
                <BreadcrumbOne
                    title="Explore Our Automotive Gallery"
                    rootUrl="/"
                    parentUrl="Home"
                    currentUrl="Gallery"
                />
                <div className="main-content">

                    {/* Start Elements Area  */}
                    <div className="rwt-gallery-area rn-section-gap">
                        <div className="container">
                            <div className="row">
                                <div className="col-lg-12 mb--20">
                                    <SectionTitle
                                        textAlign = "text-center"
                                        radiusRounded = ""
                                        subtitle = "Preserving Pristine Paint"
                                        title = "Paint Protection Films Gallery"
                                        description = "Explore our Paint Protection Films Gallery to see how we safeguard vehicles from scratches, chips, and environmental damage.<br />Discover the seamless integration of protection and aesthetics."
                                    />
                                </div>
                            </div>
                            <div className="row mt_dec--30 row--15">
                                {PaintProtectionData.map((item) => (
                                    <div className="col-lg-4 col-md-6 col-sm-6 col-12 mt--30" key={item.id}>
                                        <GalleryOne galleryItem={item} />
                                    </div>
                                ))}
                            </div>
                        </div>
                    </div>
                    {/* End Elements Area  */}

                    {/* Start Elements Area  */}
                    <div className="rwt-gallery-area rn-section-gapBottom">
                        <div className="container">
                            <div className="row">
                                <div className="col-lg-12 mb--20">
                                    <SectionTitle
                                        textAlign = "text-center"
                                        radiusRounded = ""
                                        subtitle = "Guarding Against the Elements"
                                        title = "UV Protection Gallery."
                                        description = "Step into our UV Protection Gallery and witness how we shield vehicle interiors from harmful UV rays.<br />Experience the fusion of style and practicality with our UV protection solutions."
                                    />
                                </div>
                            </div>
                            <div className="row mt_dec--30 row--15">
                                {UVProtectionData.map((item) => (
                                    <div className="col-lg-4 col-md-6 col-sm-6 col-12 mt--30" key={item.id}>
                                        <GalleryOne galleryItem={item} />
                                    </div>
                                ))}
                            </div>
                        </div>
                    </div>
                    {/* End Elements Area  */}

                    {/* Start Elements Area  */}
                    <div className="rwt-gallery-area rn-section-gapBottom">
                        <div className="container">
                            <div className="row">
                                <div className="col-lg-12 mb--20">
                                    <SectionTitle
                                        textAlign = "text-center"
                                        radiusRounded = ""
                                        subtitle = "Transforming with Color"
                                        title = "Color Change Wraps Gallery"
                                        description = "Dive into our Color Change Wraps Gallery and immerse yourself in a world of vibrant transformations.<br />Witness the power of personalized style and creativity with our dynamic color change wraps."
                                    />
                                </div>
                            </div>
                            <div className="row mt_dec--30 row--15">
                                {ColorChangeWrapsData.map((item) => (
                                    <div className="col-lg-4 col-md-6 col-sm-6 col-12 mt--30" key={item.id}>
                                        <GalleryOne galleryItem={item} />
                                    </div>
                                ))}
                            </div>
                        </div>
                    </div>
                    {/* End Elements Area  */}

                    {/* Start Elements Area  */}
                    <div className="rwt-gallery-area rn-section-gapBottom">
                        <div className="container">
                            <div className="row">
                                <div className="col-lg-12 mb--20">
                                    <SectionTitle
                                        textAlign = "text-center"
                                        radiusRounded = ""
                                        subtitle = "Enhancing Clarity, Safety, and Style"
                                        title = "Windshield Services Gallery"
                                        description = "Explore our Windshield Services Gallery to see how we elevate driving experiences with tinting, protective films, and more.<br /> Witness the fusion of functionality and elegance in our windshield solutions."
                                    />
                                </div>
                            </div>
                            <div className="row mt_dec--30 row--15">
                                {WindshieldProtectionData.map((item) => (
                                    <div className="col-lg-4 col-md-6 col-sm-6 col-12 mt--30" key={item.id}>
                                        <GalleryOne galleryItem={item} />
                                    </div>
                                ))}
                            </div>
                        </div>
                    </div>
                    {/* End Elements Area  */}

                </div>
            </Layout>
        </>
    )
}
export default Gallery;