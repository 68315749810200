import React from 'react';
import {Link} from "react-router-dom";
import CalltoActionSeven from "../../elements/calltoaction/CalltoActionSeven";
import footerOne from "../../data/footer/footerOne.json";
import ScrollTop from "./ScrollTop";
import { FiFacebook } from "react-icons/fi";
import { FaTiktok, FaYoutube } from "react-icons/fa";

const menus = [
    {
        "id": 1,
        "text": "Home",
        "url": "/"
    },
    {
        "id": 2,
        "text": "Product",
        "url": "/product"
    },
    {
        "id": 3,
        "text": "Gallery",
        "url": "/gallery"
    },
    {
        "id": 4,
        "text": "About Us",
        "url": "/about-us"
    },
    {
        "id": 5,
        "text": "Contact",
        "url": "/contact"
    },
];

const Footer = () => {
    return (
        <>
            <footer className="rn-footer footer-style-default variation-two">
                <CalltoActionSeven />
                <div className="footer-top">
                    <div className="container">
                        <div className="row">
                            {/* Start Single Widget  */}
                            <div className="col-lg-4 col-md-6 col-sm-6 col-12">
                                <div className="rn-footer-widget">
                                    <h4 className="title">Stay With Us.</h4>
                                    <div className="inner">
                                        {/* <h6 className="subtitle">{footerIntemFive.subtitle}</h6> */}
                                        <ul className="social-icon social-default justify-content-start">
                                            <li><a target="_blank" href="https://www.facebook.com/tj.autoworks"><FiFacebook /></a></li>
                                            <li><a target="_blank" href="https://www.tiktok.com/@rinvireak.tj"><FaTiktok /></a></li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                            {/* End Single Widget  */}
                        </div>
                    </div>
                </div>
            </footer>
            <ScrollTop />
        </>
    )
}

export default Footer;
