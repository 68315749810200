import React from 'react'
import SEO from "../common/SEO";
import BrandThree from '../elements/brand/BrandThree';
import Layout from '../common/Layout';

const AboutUs = () => {
    return (
        <>
            <SEO title="About Us" description="Learn more about TJ Auto Work's, your trusted partner for premium automotive solutions. Discover our mission, values, team, and commitment to excellence. Get to know us better today!" />
            <main className="page-wrapper">
                <Layout>

                {/* Start Slider Area  */}
                <div className="slider-area slider-style-1 height-850 bg_image" data-black-overlay="7" style={{backgroundImage: `url(${process.env.PUBLIC_URL}/images/about/about.jpg)`}}>
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="inner pt--80 text-center">
                                    <div>
                                        <h3 className="rn-sub-badge"><span className="theme-gradient">Elevate Your Ride</span></h3>
                                    </div>
                                    <h1 className="title display-one">Protection Your Vehicle with<br /><span className="theme-gradient">TJ Auto Work's</span></h1>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* End Slider Area  */}


                {/* Start Service Area  */}
                <div className="service-area rn-section-gapTop">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-6">
                                <div className="content">
                                    <h3 className="title">Discover Our Passion for Excellence at <span className='theme-gradient'>TJ Auto Work's</span>.</h3>
                                </div>
                            </div>
                            <div className="col-lg-6">
                                <p className="mb--10">
                                Welcome to our About page, where we invite you to discover the heart and passion driving <span className='theme-gradient'>TJ Auto Work's</span>. Here, you'll find the story behind our commitment to excellence, our dedication to customer satisfaction, and our vision for providing top-notch automotive solutions. Learn more about our team, our values, and why we're the trusted choice for discerning clients seeking exceptional service and results.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
                {/* End Service Area  */}

                {/* Start Brand Area  */}
                <div className="rwt-brand-area pb--60 pt--30">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12 mt--10">
                                <BrandThree brandStyle="brand-style-1" />
                            </div>
                        </div>
                    </div>
                </div>
                {/* End Brand Area  */}

                {/* Start CEO Message Area */}
                <div className="rn-ceo-message-area rn-section-gapTop">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-6">
                                <div className="content">
                                    <h3 className="title">A Message from Our CEO</h3>
                                    {/* CEO PHOTO */}
                                    <div className="ceo-photo">
                                        <img src="/images/team/vireak.jpg" alt="CEO" />
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-6">
                                <p className="mb--10 text-wrap">
                                    <br /><br /><br />Dear Valued Customers,

                                    <br /><br />At <span className="theme-gradient">TJ Auto Work's</span>, we're more than just a business; we're a community driven by our passion for excellence and commitment to customer satisfaction. Since our inception, our mission has been to provide unparalleled automotive solutions that not only meet but exceed the expectations of our discerning clientele.

                                    <br /><br />As the CEO of <span className="theme-gradient">TJ Auto Work's</span>, I am proud to lead a team of dedicated professionals who share our vision of delivering top-notch services with integrity, innovation, and attention to detail. Our success is built upon a foundation of trust, reliability, and a relentless pursuit of perfection in everything we do.

                                    <br /><br />We understand that every vehicle is unique, and so are the needs and preferences of our customers. That's why we take a personalized approach to every project, working closely with you to understand your goals and tailor our solutions to meet your specific requirements.

                                    <br /><br />Whether it's protecting your vehicle's paint with our premium paint protection films, enhancing its appearance with our color change wraps, or ensuring your safety and comfort with our windshield services, we're here to elevate your driving experience and exceed your expectations.

                                    <br /><br />I invite you to explore our website, learn more about our services, and discover why <span className="theme-gradient">TJ Auto Work's</span> is the trusted choice for automotive enthusiasts and businesses alike. Thank you for choosing us as your partner on your automotive journey.

                                    <br /><br />Sincerely,

                                    <br /><br />Jack
                                    <br />CEO, <span className="theme-gradient">TJ Auto Work's</span>
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
                {/* End CEO Message Area */}

                </Layout>
            </main>
        </>
    )
}

export default AboutUs;
