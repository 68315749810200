import React, { Component } from "react";

class GoogleMapStyle extends Component {
  render() {
    return (
      // Important! Always set the container height explicitly

      <div className="google-map-style-1">
        <iframe
          src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3909.098840520649!2d104.9171035!3d11.5447671!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x310951c74c9c303d%3A0xf5f3642172e294a6!2sTJ%20Auto%20Work&#39;s!5e0!3m2!1sen!2skh!4v1711772178933!5m2!1sen!2skh"
          width="100%"
          height="100%"
          style={{ borderRadius: "10px", position: "relative", border: "0" }}
          allowFullScreen=""
          loading="lazy"
          referrerPolicy="no-referrer-when-downgrade"></iframe>
      </div>
    );
  }
}

export default GoogleMapStyle;
