import PropTypes from "prop-types";
import React from 'react'


const SEO = ( {title, description} ) => {
    return (
        <>
            <meta charSet="utf-8" />
            <title>{title} - TJ Auto Work's</title>
            <meta name="robots" content="noindex, follow" />
            <meta name="description" content={description ? description : "Car Wrapping - TJ Auto Works"} />
            <meta name="viewport" content="width=device-width, initial-scale=1, shrink-to-fit=no" />
            {/* title on google search */}
            <meta property="og:title" content="TJ Auto Work's" />
            {/* description on google search */}
            <meta property="og:description" content={description} />
            {/* image on google search */}
            <meta property="og:image" content="/images/logo/logo.png" />
            {/* url on google search */}
            <meta property="og:url" content="https://tjautoworks.com/" />
            {/* title on facebook */}
            <meta property="og:site_name" content="TJ Auto Work's" />
        </>
    )
}
SEO.propTypes = {
    title: PropTypes.string
};

export default SEO;


