import React from 'react';
import {Link} from "react-router-dom";
import ScrollAnimation from "react-animate-on-scroll";

const ServiceList = [
    {
        image: '/images/why-choose-us/expertise.png',
        title: 'Expertise',
        description: 'Our team consists of skilled professionals with years of experience in the automotive industry. From design to installation, we handle every aspect of our services with precision and expertise.'
    },
    {
        image: '/images/why-choose-us/quality-assurance.png',
        title: 'Quality Assurance',
        description: 'We use only the highest quality materials and products sourced from trusted suppliers. This ensures durability, longevity, and superior results for every project.'
    },
    {
        image: '/images/why-choose-us/satisfaction.png',
        title: 'Customer Satisfaction',
        description: 'Your satisfaction is our priority. We work closely with each customer to understand their needs and preferences, providing personalized solutions that meet and exceed expectations.'
    },
    {
        image: '/images/why-choose-us/innovation.png',
        title: 'Innovation',
        description: 'We stay up-to-date with the latest trends and technologies in the automotive industry to offer cutting-edge solutions that enhance performance, aesthetics, and functionality.'
    },
    {
        image: '/images/why-choose-us/community.png',
        title: 'Community Engagement',
        description: 'We are committed to giving back to the community and supporting local initiatives. Building strong relationships with our customers and community partners is an integral part of our business ethos.'
    }
]
const ServiceThree = ({textAlign, serviceStyle}) => {
    return (
        <div className="row row--15 service-wrapper">
              {ServiceList.map( (val , i) => (
                <div className="col-lg-4 col-md-6 col-sm-6 col-12" key={i}>
                    <ScrollAnimation
                    animateIn="fadeInUp"
                    animateOut="fadeInOut"
                    animateOnce={true}>
                        <div className={`service ${serviceStyle} ${textAlign}`}>
                            <div className="inner">
                                <div className="image">
                                    <img src={`${val.image}`} alt="card Images" />
                                </div>
                                <div className="content">
                                    <h4 className="title"><Link to="#service" dangerouslySetInnerHTML={{__html: val.title}}></Link></h4>
                                    <p className="description b1 color-gray mb--0" dangerouslySetInnerHTML={{__html: val.description}}></p>
                                </div>
                            </div>
                        </div>
                    </ScrollAnimation>
                </div>
            ))}
        </div>
    )
}
export default ServiceThree;