import React from 'react';
import { FaTiktok, FaYoutube } from 'react-icons/fa';
import { FiFacebook, FiMapPin, FiPhone } from "react-icons/fi";

const HeaderTopBar = () => {
    return (
        <div className="header-top-bar">
            <div className="container">
                <div className="row align-items-center">
                    <div className="col-lg-4 col-md-12 col-12">

                    </div>
                    <div className="col-lg-8 col-md-12 col-12">
                        <div className="header-right">
                            <div className="address-content">
                                <p>
                                    <a target="_blank" href="https://maps.app.goo.gl/Cwjyw6675A7qtxQ4A">
                                        <FiMapPin /><span>Phnom Penh, Cambodia</span>
                                    </a>
                                </p>
                                <p><FiPhone /><span><a href="tel:010-744-401">010-744-401</a></span></p>
                            </div>
                            <div className="social-icon-wrapper">
                                <ul className="social-icon social-default icon-naked">
                                    <li><a target="_blank" href="https://www.facebook.com/tj.autoworks"><FiFacebook /></a></li>
                                    <li><a target="_blank" href="https://www.tiktok.com/@rinvireak.tj"><FaTiktok /></a></li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default HeaderTopBar;
