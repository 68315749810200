import React from 'react';
import {Link} from "react-router-dom";
import ScrollAnimation from "react-animate-on-scroll";

const ServiceList = [
    {
        image: '/images/service/service-01.jpg',
        title: 'Paint Protection Films',
        description: 'Shield your vehicle\'s paint from scratches, chips, and other damage with our premium paint protection films.'
    },
    {
        image: '/images/service/service-02.jpg',
        title: 'UV Protection',
        description: 'Protect your car\'s interior surfaces from harmful UV rays with our specialized UV protection services.'
    },
    {
        image: '/images/service/service-03.jpg',
        title: 'Color Change Wraps',
        description: 'Transform the look of your vehicle with our custom color change wraps with our premium films.'
    },
    {
        image: '/images/service/service-04.jpg',
        title: 'Windshield Services',
        description: 'Enhance your driving experience and ensure safety on the road with our premium windshield.'
    }
]
const ServiceTwo = ({textAlign, cardStyle}) => {
    return (
        <div className="row row--15 service-wrapper">
              {ServiceList.map( (val , i) => (
                <div className="col-xl-3 col-lg-6 col-md-6 col-sm-6 col-12" key={i}>
                    <ScrollAnimation
                    animateIn="fadeInUp"
                    animateOut="fadeInOut"
                    animateOnce={true}>
                        <div className={`card-box ${cardStyle} ${textAlign}`}>
                            <div className="inner">
                                <div className="image">
                                    <Link to="/product">
                                        <img src={`${val.image}`} alt="card Images" />
                                    </Link>
                                </div>
                                <div className="content">
                                    <h4 className="title mb--20"><Link to="#service" dangerouslySetInnerHTML={{__html: val.title}}></Link></h4>
                                    <p className="description b1 color-gray mb--0" dangerouslySetInnerHTML={{__html: val.description}}></p>
                                    <Link className="btn-default btn-small btn-border" to="/product">Read More</Link>
                                </div>
                            </div>
                        </div>
                    </ScrollAnimation>
                </div>
            ))}
        </div>
    )
}
export default ServiceTwo;