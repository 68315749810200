import React from 'react'
import SEO from "../common/SEO";

import Slider from "react-slick";
import { BannerActivation } from "../utils/script";
import Separator from "../elements/separator/Separator";
import SectionTitle from "../elements/sectionTitle/SectionTitle";
import TestimonialOne from "../elements/testimonial/TestimonialOne";
import ServiceTwo from '../elements/service/ServiceTwo';
import ServiceThree from '../elements/service/ServiceThree';

import Layout from '../common/Layout';
import TeamOne from '../elements/team/TeamOne';



const BannerData = [
    {
        image: "/images/banner/banner01.jpg",
        title: "<span class='theme-gradient'>No.1</span> Cambodia<br><small>Car Wrapping Skills.</small>",
        description: "We help our clients succeed by providing top-notch car wrapping services."
    },
    {
        image: "/images/banner/banner02.jpg",
        title: "Protect Your Vehicle.",
        description: "Transform Your Vehicle with Professional Car Wrapping Services"
    },
    {
        image: "/images/banner/banner03.jpg",
        // title: "Startup Agency.",
        // description: "We help our clients succeed by creating brand identities, digital experiences, and print materials."
    },
]


const Home = () => {
    return (
        <>
            <SEO title="Home" description="Welcome to TJ Auto Work's! Discover premium automotive solutions, including car wrapping, paint protection, UV shielding, and more. Transform your vehicle with our expert services. Contact us today!" />
            <Layout>
                {/* Start Slider Area  */}
                <Slider className="slider-area slider-style-4 slider-dot rn-slick-dot rn-slick-arrow" {...BannerActivation}>
                    {BannerData.map((data, index) => (
                        <div key={index} className="single-slide">
                            <div className="height-950 bg-overlay bg_image" style={{backgroundImage: `url(${process.env.PUBLIC_URL} ${data.image})`}}>
                                <div className="container">
                                    <div className="row row--30 align-items-center">
                                        <div className="order-2 order-lg-1 col-lg-7">
                                            <div className="inner text-start">
                                                <h1 className="title" dangerouslySetInnerHTML={{__html: data.title}}></h1>
                                                <p className="description" dangerouslySetInnerHTML={{__html: data.description}}></p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    ))}
                </Slider>
                {/* End Slider Area  */}

                <div className="rn-service-area rn-section-gap">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12"></div>
                                <div className="col-lg-12">
                                    <SectionTitle
                                        textAlign = "text-center"
                                        radiusRounded = ""
                                        subtitle = "What we can do for you"
                                        title = "Services provide for you."
                                        description = "<span class='theme-gradient'>At TJ Auto Work's</span>, <br />we specialize in professional car wrapping services tailored to enhance the appearance, or protect your vehicle's paint. <br />Our expert team brings your vision to life with precision and style."
                                    />
                                </div>
                            </div>
                            <ServiceTwo
                                cardStyle = "card-style-1"
                                textAlign = "text-start"
                            />
                        </div>
                    </div>
                    {/* End Service Area  */}


                <Separator />
                <div className="rwt-portfolio-area rn-section-gap">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                    <SectionTitle
                                        textAlign = "text-center"
                                        radiusRounded = ""
                                        subtitle = "Why Choose Us"
                                        title = "Clients Choose us!"
                                        description = "At TJ Auto Work's, we understand that our clients have choices, and we're honored they choose us for their automotive needs. Here's why discerning individuals and businesses consistently choose us:"
                                    />
                            </div>
                        </div>
                        <ServiceThree
                            serviceStyle = "service__style--2"
                            textAlign = "text-center"
                        />
                    </div>
                </div>


                <Separator />

                 {/* Start Elements Area  */}
                <div className="rwt-team-area rn-section-gap">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                    <SectionTitle
                                        textAlign = "text-center"
                                        radiusRounded = ""
                                        subtitle = "Our Experts"
                                        title = "Our Expert Team"
                                        description = ""
                                    />
                            </div>
                        </div>
                        <TeamOne column="col-lg-4 col-md-6 col-12 mt--30" teamStyle="team-style-default border-gradient" />
                    </div>
                </div>
                {/* End Elements Area  */}
                <Separator />


                {/* Start Testimonial Area  */}
                {/* <div className="rwt-testimonial-area rn-section-gap">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <SectionTitle
                                    textAlign = "text-center"
                                    radiusRounded = ""
                                    subtitle = "Client Feedback"
                                    title = "What People Are Saying."
                                    description = "Discover the voices of satisfied customers and partners in our 'What People Are Saying' section.<br />Hear firsthand testimonials, reviews, and feedback about our services, quality, and commitment to excellence.<br />Join the chorus of delighted clients who have experienced exceptional results with <span class='theme-gradient'>TJ Auto Work's</span>."
                                />
                            </div>
                        </div>
                        <TestimonialOne column="col-lg-4 col-md-6 col-sm-6 col-12 mt--30" teamStyle="card-style-default testimonial-style-one style-two border-gradient" />
                    </div>
                </div> */}
                {/* End Testimonial Area  */}
            </Layout>
        </>
    )
}
export default Home;