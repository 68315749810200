import React from 'react';
import SEO from "../common/SEO";
import Layout from "../common/Layout";
import BreadcrumbOne from "../elements/breadcrumb/BreadcrumbOne";
import SectionTitle from "../elements/sectionTitle/SectionTitle";
import TabPPF from '../elements/tab/TabPPF';
import Separator from '../elements/separator/Separator';
import SlipColor from '../elements/split/SlipColor';
import SlpitUv from '../elements/split/SlpitUv';
import SlipWindshield from '../elements/split/SlipWindshield';


const Product = () => {
    return (
        <>
            <SEO title="Product" description="Explore our range of premium automotive products, including car wrapping, paint protection films, UV shielding, and more. Elevate your vehicle's appearance and protection with our expert solutions. Browse now!" />
            <Layout>
                <BreadcrumbOne
                    title="Explore Our Premium <br />Automotive Products and Services"
                    rootUrl="/"
                    parentUrl="Home"
                    currentUrl="Product"
                />
                <div className="main-content">

                    {/* Start Elements Area  */}
                    <div className="rwt-tab-area rn-section-gap">
                        <div className="container">
                            <div className="row mb--40">
                                <div className="col-lg-12">
                                    <SectionTitle
                                        textAlign = "text-center"
                                        radiusRounded = ""
                                        subtitle = "Preserve Your Vehicle's Pristine Finish"
                                        title = "Paint Protection Films"
                                        description = "Protect your investment with our advanced Paint Protection Films. <br />Engineered to provide an invisible shield against scratches, chips, and environmental damage, our films keep your car looking newer for longer. <br />Trust in our expertise to safeguard your vehicle's paint and maintain its showroom shine."
                                    />
                                </div>
                            </div>
                        </div>
                        <TabPPF />
                    </div>
                    {/* End Elements Area  */}

                    <Separator />

                    {/* Start Elements Area  */}
                    <div className="rwt-gallery-area rn-section-gap">
                        <div className="container">
                            <div className="row">
                                <div className="col-lg-12 mb--20">
                                    <SectionTitle
                                        textAlign = "text-center"
                                        radiusRounded = ""
                                        subtitle = "Defend Your Interior, Enhance Your Comfort"
                                        title = "UV Protection"
                                        description = "Shield your car's interior from harmful UV rays with our specialized UV protection solutions. <br />Prevent fading, cracking, and deterioration of upholstery, dashboard, and trim, while enhancing comfort and longevity. <br />Experience the ultimate protection against sun damage and maintain the beauty and integrity of your vehicle's interior with our UV protection services."
                                    />
                                </div>
                            </div>
                        </div>
                        <SlpitUv />
                    </div>
                    {/* End Elements Area  */}

                    <Separator />

                    {/* Start Elements Area  */}
                    <div className="rwt-gallery-area rn-section-gap">
                        <div className="container">
                            <div className="row">
                                <div className="col-lg-12 mb--20">
                                    <SectionTitle
                                        textAlign = "text-center"
                                        radiusRounded = ""
                                        subtitle = "Transform Your Vehicle, Express Your Style"
                                        title = "Color Change Wraps"
                                        description = "Unleash your creativity and make a statement on the road with our Color Change Wraps. Explore a diverse range of colors, finishes, and textures to customize your vehicle's exterior appearance to your unique preferences. With expert installation and durable materials, our Color Change Wraps offer a versatile and cost-effective solution for updating and personalizing your vehicle's look."
                                    />
                                </div>
                            </div>
                        </div>
                        <SlipColor />
                    </div>
                    {/* End Elements Area  */}

                    <Separator />

                    {/* Start Elements Area  */}
                    <div className="rwt-gallery-area rn-section-gap">
                        <div className="container">
                            <div className="row">
                                <div className="col-lg-12 mb--20">
                                    <SectionTitle
                                        textAlign = "text-center"
                                        radiusRounded = ""
                                        subtitle = "Enhance Visibility, Ensure Safety"
                                        title = "Windshield Services"
                                        description = "Elevate your driving experience with our comprehensive Windshield Services. From tinting to protective coatings, our expert technicians provide solutions designed to improve visibility, reduce glare, and safeguard your windshield from damage. Whether you seek enhanced aesthetics, increased comfort, or added safety features, trust in our professional services tailored to meet your needs."
                                    />
                                </div>
                            </div>
                        </div>
                        <SlipWindshield />
                    </div>
                    {/* End Elements Area  */}
                </div>
            </Layout>
        </>
    )
}
export default Product;