import React from 'react';
import CountUp from 'react-countup';
import TrackVisibility from "react-on-screen";
import ScrollAnimation from "react-animate-on-scroll";

const Data = [
    {
        countTitle: 'Super Metallic Ceramic',
    },
    {
        countTitle: 'Matte Chrome',
    },
    {
        countTitle: 'Glossy Metallic',
    },
    {
        countTitle: 'Super Glossy',
    },
    {
        countTitle: 'Original Colors',
    },
    {
        countTitle: '3D Psychedelic',
    },
    {
        countTitle: 'Magic Golden',
    },
    {
        countTitle: 'Metal Brushed',
    },
    {
        countTitle: 'Pearl Metallic',
    },
    {
        countTitle: 'Crystal Metallic',
    },
];
const SlipColor = () => {
    return (
        <div className="rn-splite-style bg-color-blackest">
            <div className="split-wrapper">
                <div className="row no-gutters radius-10 align-items-center">
                    <div className="order-2 order-lg-1 col-lg-12 col-xl-6 col-12">
                        <div className="split-inner">
                            <ScrollAnimation
                            animateIn="fadeInUp"
                            animateOut="fadeInOut"
                            animateOnce={true}>
                                <h4 className="title">Explore Our Stunning Range of Specialty Finishes for Vehicle Wraps</h4>
                            </ScrollAnimation>
                            <div className="row">
                                {Data.map((data, index) => (
                                    <div className="col-lg-6 col-md-6 col-sm-6 col-12" key={index}>
                                        <div className="count-box counter-style-4 text-start">
                                            <h5 className="counter-title">{data.countTitle}</h5>
                                        </div>
                                    </div>
                                ))}
                            </div>
                        </div>
                    </div>
                    <div className="order-1 order-lg-2 col-lg-12 col-xl-6 col-12">
                        <div className="thumbnail">
                            <img src="./images/product/color-wraps.jpg" alt="color change wraps" />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
export default SlipColor;