import React from 'react';
import {Link, useLocation} from "react-router-dom";

const Nav = () => {
    const location = useLocation();
    return (
        <ul className="mainmenu">
            <li className={location.pathname === "/" ? "active" : ""}>
                <Link to="/">Home</Link>
            </li>
            <li className={location.pathname === "/product" ? "active" : ""}>
                <Link to="/product">Product</Link>
            </li>
            <li className={location.pathname === "/gallery" ? "active" : ""}>
                <Link to="/gallery">Gallery</Link>
            </li>
            <li className={location.pathname === "/about-us" ? "active" : ""}>
                <Link to="/about-us">About Us</Link>
            </li>
            <li className={location.pathname === "/contact" ? "active" : ""}>
                <Link to="/contact">Contact</Link>
            </li>
        </ul>
    )
}
export default Nav;
