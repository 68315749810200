import React from 'react';
import { FiFacebook, FiTwitter, FiInstagram, FiLinkedin } from "react-icons/fi";
import {Link} from "react-router-dom";
import ScrollAnimation from "react-animate-on-scroll";
import { FaTiktok } from 'react-icons/fa';

const teamData = [
    {
        image: 'vireak',
        name: 'Rin Vireak',
        designation: 'CEO',
        location: 'JACK',
        description: '',
        socialNetwork: [
            {
                icon: <FiFacebook />,
                url: 'https://www.facebook.com/Rinvireakofficial'
            },
            {
                icon: <FaTiktok />,
                url: 'https://www.tiktok.com/@rinvireak.tj'
            }
        ]

    },
    {
        image: 'channat',
        name: 'Hong Channat',
        designation: 'General Manager',
        location: 'GeGe',
        description: '',
        socialNetwork: [
            {
                icon: <FiFacebook />,
                url: 'https://www.facebook.com/hong.channat.5'
            },
            {
                icon: <FaTiktok />,
                url: 'https://www.tiktok.com/@hongchannat.gege'
            }
        ]

    },
    {
        image: 'sokunthea',
        name: 'Yeu Sokunthea',
        designation: 'Supervisor',
        location: 'Steven',
        description: '',
        socialNetwork: [
            {
                icon: <FiFacebook />,
                url: 'https://www.facebook.com/yeu.sokunthea'
            },

            {
                icon: <FaTiktok />,
                url: 'https://www.tiktok.com/@tjautowork'
            }
        ]

    },
]


const TeamOne = ({column , teamStyle}) => {
    return (
        <div className="row row--15">
            {teamData.map((data, index) => (
                <div className={`${column}`} key={index}>
                    <div className={`rn-team ${teamStyle}`}>
                        <ScrollAnimation
                        animateIn="fadeInUp"
                        animateOut="fadeInOut"
                        animateOnce={true}>
                            <div className="inner">
                                <figure className="thumbnail">
                                    <img src={`./images/team/${data.image}.jpg`} alt="Corporate React Template" />
                                </figure>
                                <figcaption className="content">
                                    <h2 className="title">{data.name}</h2>
                                    <h6 className="subtitle theme-gradient">{data.designation}</h6>
                                    <span className="team-form">
                                        <img src="./images/team/location.svg" alt="Corporate React Template" />
                                        <span className="location">{data.location}</span>
                                    </span>
                                    <p className="description">{data.description}</p>

                                    <ul className="social-icon social-default icon-naked mt--20" >
                                        {data.socialNetwork.map((social, index) =>
                                            <li key={index}><a target="_blank" href={`${social.url}`}>{social.icon}</a></li>
                                        )}
                                    </ul>
                                </figcaption>
                            </div>
                        </ScrollAnimation>
                    </div>
                </div>
            ))}
        </div>
    )
}

export default TeamOne;
